@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f172a;
  color: white;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Prevent horizontal overflow */
body {
  overflow-x: hidden;
}

/* For Safari and older browsers that don't support scroll-behavior */
.js-scroll {
  transition: all 0.5s ease-in-out;
}

/* Ensure sections take up appropriate space */
section {
  min-height: 50vh;
  width: 100%;
}

/* Ensure images don't overflow their containers */
img {
  max-width: 100%;
  height: auto;
}

* {
    box-sizing: border-box;
  }
  